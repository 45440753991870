<template>
  <div>
    <v-row
      class="align-items-center product-facer-filter-desktop rounded-md grey lighten3 mb-3"
    >
      <template>
        <v-col cols="12" sm="auto" md="5" v-if="!hidePromoFilter">
          <ProductPromoFilter
            :promoFilter="promoFilter"
            @handlePromoFilter="handlePromoFilter"
            @handleCoopFilter="handleCoopFilter"
            @handleGlutenFilter="handleGlutenFilter"
            @handleNewFilter="handleNewFilter"
          />
        </v-col>

        <v-col cols="12" sm="auto" md="7" class="d-flex align-center px-1">
          <transition>
            <v-btn
              v-if="hasFacets"
              link
              class="productFiltersBtn ml-auto rounded-pill text-none font-weight-regular px-3 mr-3"
              outlined
              elevation="0"
              :disabled="!facets"
              @click="menu = !menu"
            >
              <span class="mr-8">{{
                $tc("filter.filters", getFacetsLength)
              }}</span>
              <v-spacer />
              <v-icon>$expand</v-icon>
            </v-btn>
          </transition>

          <transition>
            <ProductSortFilter
              v-if="filteredProductsCount > 4"
              @handleSortFilter="handleSortFilter"
              :sortFilter="sortFilter"
            />
          </transition>
          <v-btn
            color="default"
            class="clear-filter white"
            outlined
            v-if="getFacetsLength > 0"
            text
            @click="clearAllFilters"
          >
            <v-icon color="default" small>$clean</v-icon>
            <span> {{ $t("filter.clear") }}</span>
          </v-btn>
        </v-col>
        <!-- <v-col class="d-flex align-center justify-end font-weight-bold">{{
          $tc("filter.products", totItems)
        }}</v-col> -->
      </template>
      <transition-expand>
        <v-col cols="12" v-if="menu">
          <div class="d-flex  justify-start grey lighten3 pa-3">
            <div
              v-for="facet in facetsFiltered"
              :key="facet.name"
              class="product-facer-filter-desktop-category-row px-3 w-100"
            >
              <h5 class="font-weight-bold">
                {{ facet.name }}
                <span
                  class="ml-2 text-caption"
                  v-if="
                    facetsFilter[facet.parameterName] &&
                      facetsFilter[facet.parameterName].length > 0
                  "
                >
                  {{
                    $tc(
                      "filter.filters",
                      facetsFilter[facet.parameterName].length
                    )
                  }}
                </span>
              </h5>
              <filtered-checkbox-list
                class="px-2"
                :facet="facet"
                :facetsFilter="facetsFilter"
                :filteredProductsQty="productsQty"
                @handleFacetFilter="handleFacetFilter"
              />
            </div>
          </div>
          <div class="d-flex justify-end mb-4 px-4">
            <v-btn
              class="rounded-pill"
              color="primary"
              outlined
              @click="menu = false"
              >{{ $t("filter.close") }}</v-btn
            >
            <v-btn
              color="primary"
              class="ml-5 rounded-pill"
              depressed
              @click="menu = false"
            >
              {{ $t("filter.apply") }}
            </v-btn>
          </div>
        </v-col>
      </transition-expand>
    </v-row>
    <!-- <v-divider class="mt-1 mb-3" /> -->
  </div>
</template>
<style global lang="scss">
.product-facer-filter-desktop {
  .clear-filter {
    border: 1px solid var(--v-default-base) !important;
  }
  .product-facer-filter-desktop-category-row {
    &:not(:last-child) {
      border-right: 1px solid var(--v-default-lighten1);
    }
  }

  .productFiltersBtn.v-btn:not(.v-btn--round).v-size--default {
    height: 50px;
    border: 1px solid #343f59;
    min-width: 175px;
    //border-radius: 8px !important;
  }
  .v-icon {
    font-size: 15px;
    font-weight: bold;
    color: var(--v-default-base);
  }
  .v-text-field--enclosed .v-input__append-inner {
    margin-top: 13px;
  }
  .v-input .v-label {
    line-height: 17px;
  }
  .v-input--selection-controls {
    margin-top: 6px;
  }
}
</style>
<script>
import FilteredCheckboxList from "./FilteredCheckboxList.vue";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import TransitionExpand from "@/components/TransitionExpand.vue";
import ProductPromoFilter from "@/components/product/filters/ProductPromoFilter";
export default {
  name: "ProductFacetFilter",
  components: {
    FilteredCheckboxList,
    ProductSortFilter,
    TransitionExpand,
    ProductPromoFilter
  },
  data() {
    return {
      menu: false
    };
  },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    hideFilterString: { type: String, default: "" },
    filteredProductsCount: { type: Number, required: false },
    productsQty: { type: Number },
    hidePromoFilter: { type: Boolean, default: false },
    promoFilter: { type: Boolean },
    totItems: { type: Number },
    sortFilter: { type: String }
  },
  computed: {
    facetFilter: item => {
      return item.filteredCount > 0;
    },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    },
    hasFacets() {
      return this.facets && this.facets.length > 0;
    },
    getFacetsLength() {
      let facetsLength = 0;
      for (const prop in this.facetsFilter) {
        facetsLength += this.facetsFilter[prop].length;
      }
      return facetsLength;
    }
  },
  methods: {
    clearAllFilters() {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: {}
      });
      this.$emit("handlePromoFilter", false);
      this.$emit("handleNewFilter", false);
      this.$emit("handleSortFilter", "");
      global.EventBus.$emit("filterChanged");
    },
    handleFacetFilter(value) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: value
      });
      global.EventBus.$emit("filterChanged");
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleCoopFilter(coop) {
      this.$emit("handleCoopFilter", coop);
    },
    handleGlutenFilter(gluten) {
      this.$emit("handleGlutenFilter", gluten);
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    },
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
      console.log("BBBBB", sort);
    },
    facetLabel(item) {
      return item.name + " (" + item.filteredCount + ")";
    },
    facetDisabled: facet => facet.filteredCount == 0
  }
};
</script>

<style></style>
