<template>
  <div v-click-outside="onClickOutside">
    <v-checkbox
      multiple
      hide-details
      v-for="value in filter"
      :key="value.id"
      :value="value.id"
      :disabled="value.filteredCount == 0"
      v-model="selectedFacetsXS[facet.parameterName]"
      :label="`${value.name} (${value.filteredCount})`"
      @click="handleFacetFilter()"
      class="product-facer-filter-desktop-category-row-item d-flex align-center"
    >
      <!-- <template v-slot:prepend v-if="value.filteredCount != 0">
        <v-icon class="font-weight-regular">$star</v-icon>
       <v-icon class="font-weight-regular">starFull</v-icon>
      </template> -->
    </v-checkbox>
    <div class="mt-2 d-flex">
      <a
        :aria-label="$t('filter.showMore')"
        :title="$t('filter.showMore')"
        v-if="facet.values.length > 6 && panelLength == 6"
        class="text-caption font-weight-bold"
        @click="panelLength = facet.values.length"
      >
        {{ $t("filter.showMore") }}
      </a>
      <a
        :aria-label="$t('filter.showLess')"
        :title="$t('filter.showLess')"
        v-else-if="facet.values.length > 6"
        class="text-caption font-weight-bold"
        @click="panelLength = 6"
      >
        {{ $t("filter.showLess") }}
      </a>
    </div>
  </div>
</template>
<style lang="scss">
.product-facer-filter-desktop-category-row-item {
  flex-direction: row-reverse;
}
</style>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "FilterCheckboxList",
  data() {
    return {
      panelLength: 6
    };
  },
  props: {
    facet: { type: Object },
    facetsFilter: { type: Object },
    filteredProductsQty: { type: Number }
  },
  computed: {
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    filter() {
      return this.facet.values.slice(0, this.panelLength);
    },
    calcMaxHeight() {
      if (this.filteredProductsQty > 0 && this.filteredProductsQty <= 4) {
        return `${window.innerHeight - 278}px`;
      }
      return "100%";
    }
  },
  methods: {
    handleFacetFilter() {
      this.$emit("handleFacetFilter", this.selectedFacetsXS);
    },
    onClickOutside() {
      this.panelLength = 6;
    }
  }
};
</script>
